import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-custom-email-templates",
  templateUrl: "./custom-email-templates.component.html",
  styleUrls: ["./custom-email-templates.component.css"],
})
export class CustomEmailTemplatesComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
