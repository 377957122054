import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./components/login/login.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { AuthGuard } from "./auth.guard";
import { RegistrationComponent } from "./components/registration/registration.component";
import { ContactComponent } from "./components/contact/contact.component";
import { OurEstablishmentComponent } from "./components/our-establishment/our-establishment.component";
import { WebsiteDevelopmentComponent } from "./components/website-development/website-development.component";
import { ECommerceComponent } from "./components/e-commerce/e-commerce.component";
import { WebApplicationComponent } from "./components/web-application/web-application.component";
import { DigitalMarketingComponent } from "./components/digital-marketing/digital-marketing.component";
import { GraphicsDevelopmentComponent } from "./components/graphics-development/graphics-development.component";
import { HireReactjsDevelopersComponent } from "./components/hire-reactjs-developers/hire-reactjs-developers.component";
import { HireNodejsDevelopersComponent } from "./components/hire-nodejs-developers/hire-nodejs-developers.component";
import { HireAngularjsDevelopersComponent } from "./components/hire-angularjs-developers/hire-angularjs-developers.component";
import { ClienteleComponent } from "./components/clientele/clientele.component";
import { ExpertiseExperienceComponent } from "./components/expertise-experience/expertise-experience.component";
import { BlogComponent } from "./components/blog/blog.component";
import { BlogOneComponent } from "./components/blog-one/blog-one.component";
import { BlogTwoComponent } from "./components/blog-two/blog-two.component";
import { BlogThreeComponent } from "./components/blog-three/blog-three.component";
import { CtoServiceComponent } from "./cto-service/cto-service.component";
import { CustomEmailTemplatesComponent } from "./components/custom-email-templates/custom-email-templates.component";
import { HireFrontEndDevelopersComponent } from "./components/hire-front-end-developers/hire-front-end-developers.component";
import { HireFullStackDevelopersComponent } from "./components/hire-full-stack-developers/hire-full-stack-developers.component";
import { HireHybridAppDevelopersComponent } from "./components/hire-hybrid-app-developers/hire-hybrid-app-developers.component";
import { HireUiUxDevelopersComponent } from "./components/hire-ui-ux-developers/hire-ui-ux-developers.component";
import { HireProjectManagerComponent } from "./components/hire-project-manager/hire-project-manager.component";
import { HireSalesForceDevelopersComponent } from "./components/hire-sales-force-developers/hire-sales-force-developers.component";
import { MobileAppDevelopmentComponent } from "./components/mobile-app-development/mobile-app-development.component";
import { DedicatedDevelopmentComponent } from "./components/dedicated-development/dedicated-development.component";
import { CustomSoftwareDevelopmentComponent } from "./components/custom-software-development/custom-software-development.component";

const routes: Routes = [
  { path: "", component: HomepageComponent },
  { path: "register", component: RegistrationComponent },
  { path: "login", component: LoginComponent },
  { path: "contact", component: ContactComponent },
  { path: "our-establishment", component: OurEstablishmentComponent },
  { path: "expertise-experience", component: ExpertiseExperienceComponent },
  { path: "website-development", component: WebsiteDevelopmentComponent },
  { path: "e-commerce", component: ECommerceComponent },
  { path: "web-application", component: WebApplicationComponent },
  { path: "digital-marketing", component: DigitalMarketingComponent },
  { path: "graphics-development", component: GraphicsDevelopmentComponent },
  { path: "cto-services", component: CtoServiceComponent },
  { path: "custom-email-templates", component: CustomEmailTemplatesComponent },
  { path: "mobile-app-development", component: MobileAppDevelopmentComponent },
  {
    path: "dedicated-development-center",
    component: DedicatedDevelopmentComponent,
  },
  {
    path: "custom-software-development",
    component: CustomSoftwareDevelopmentComponent,
  },

  {
    path: "hire-angularjs-developers",
    component: HireAngularjsDevelopersComponent,
  },
  {
    path: "hire-reactjs-developers",
    component: HireReactjsDevelopersComponent,
  },
  {
    path: "hire-front-end-developers",
    component: HireFrontEndDevelopersComponent,
  },
  { path: "hire-nodejs-developers", component: HireNodejsDevelopersComponent },
  {
    path: "hire-full-stack-developers",
    component: HireFullStackDevelopersComponent,
  },
  {
    path: "hire-hybrid-app-developers",
    component: HireHybridAppDevelopersComponent,
  },
  {
    path: "hire-ui-ux-developers",
    component: HireUiUxDevelopersComponent,
  },
  {
    path: "hire-project-manager",
    component: HireProjectManagerComponent,
  },
  {
    path: "hire-sales-force-developers",
    component: HireSalesForceDevelopersComponent,
  },
  {
    path: "blog",
    component: BlogComponent,
  },
  {
    path: "blog-hybrid-app",
    component: BlogOneComponent,
  },
  {
    path: "blog-hr",
    component: BlogTwoComponent,
  },
  {
    path: "blog-ecommerce",
    component: BlogThreeComponent,
  },
  {
    path: "clientele",
    component: ClienteleComponent,
  },

  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
