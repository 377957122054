import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment, httpOptions } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ServicesService {
  constructor(private http: HttpClient) {}
  post_api_call(path: any, data: any): Observable<any> {
    return this.http
      .post(environment.apiURL + path, data, httpOptions)
      .pipe(map((response: Response) => response));
  }
  emailpattern() {
    return /^[a-zA-Z0-9._%+,-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9]+$/;
  }
}
