import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hire-hybrid-app-developers",
  templateUrl: "./hire-hybrid-app-developers.component.html",
  styleUrls: ["./hire-hybrid-app-developers.component.css"],
})
export class HireHybridAppDevelopersComponent implements OnInit {
  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
