import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-digital-marketing",
  templateUrl: "./digital-marketing.component.html",
  styleUrls: ["./digital-marketing.component.css"],
})
export class DigitalMarketingComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
