import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HomepageComponent } from "./components/homepage/homepage.component";
import { LoginComponent } from "./components/login/login.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ApiService } from "./services/api.service";
import { AuthGuard } from "./auth.guard";
import { CustomHttpInterceptorService } from "./services/custom-http-interceptor.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { RegistrationComponent } from "./components/registration/registration.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { ContactComponent } from "./components/contact/contact.component";
import { OurEstablishmentComponent } from "./components/our-establishment/our-establishment.component";
import { ExpertiseExperienceComponent } from "./components/expertise-experience/expertise-experience.component";
import { WebsiteDevelopmentComponent } from "./components/website-development/website-development.component";
import { ECommerceComponent } from "./components/e-commerce/e-commerce.component";
import { WebApplicationComponent } from "./components/web-application/web-application.component";
import { DigitalMarketingComponent } from "./components/digital-marketing/digital-marketing.component";
import { GraphicsDevelopmentComponent } from "./components/graphics-development/graphics-development.component";
import { HireReactjsDevelopersComponent } from "./components/hire-reactjs-developers/hire-reactjs-developers.component";
import { HireNodejsDevelopersComponent } from "./components/hire-nodejs-developers/hire-nodejs-developers.component";
import { HireAngularjsDevelopersComponent } from "./components/hire-angularjs-developers/hire-angularjs-developers.component";
import { ClienteleComponent } from "./components/clientele/clientele.component";
import { BlogComponent } from "./components/blog/blog.component";
import { BlogOneComponent } from "./components/blog-one/blog-one.component";
import { BlogTwoComponent } from "./components/blog-two/blog-two.component";
import { BlogThreeComponent } from "./components/blog-three/blog-three.component";
import { ContactFormComponent } from "./components/contact-form/contact-form.component";
import { ServicesService } from "./services/services.service";
import { CtoServiceComponent } from "./cto-service/cto-service.component";
import { CustomEmailTemplatesComponent } from "./components/custom-email-templates/custom-email-templates.component";
import { HireFrontEndDevelopersComponent } from "./components/hire-front-end-developers/hire-front-end-developers.component";
import { HireFullStackDevelopersComponent } from "./components/hire-full-stack-developers/hire-full-stack-developers.component";
import { HireUiUxDevelopersComponent } from "./components/hire-ui-ux-developers/hire-ui-ux-developers.component";
import { HireHybridAppDevelopersComponent } from './components/hire-hybrid-app-developers/hire-hybrid-app-developers.component';
import { HireProjectManagerComponent } from './components/hire-project-manager/hire-project-manager.component';
import { HireSalesForceDevelopersComponent } from './components/hire-sales-force-developers/hire-sales-force-developers.component';
import { MobileAppDevelopmentComponent } from './components/mobile-app-development/mobile-app-development.component';
import { DedicatedDevelopmentComponent } from './components/dedicated-development/dedicated-development.component';
import { CustomSoftwareDevelopmentComponent } from './components/custom-software-development/custom-software-development.component';

export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, "./assets/i18/", ".json");
};

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    RegistrationComponent,
    LoginComponent,
    NotFoundComponent,
    DashboardComponent,
    FooterComponent,
    HeaderComponent,
    ContactComponent,
    ContactFormComponent,
    OurEstablishmentComponent,
    ExpertiseExperienceComponent,
    WebsiteDevelopmentComponent,
    ECommerceComponent,
    WebApplicationComponent,
    DigitalMarketingComponent,
    GraphicsDevelopmentComponent,
    HireReactjsDevelopersComponent,
    HireNodejsDevelopersComponent,
    HireAngularjsDevelopersComponent,
    ClienteleComponent,
    BlogComponent,
    BlogOneComponent,
    BlogTwoComponent,
    BlogThreeComponent,
    CtoServiceComponent,
    CustomEmailTemplatesComponent,
    HireFrontEndDevelopersComponent,
    HireFullStackDevelopersComponent,
    HireUiUxDevelopersComponent,
    HireHybridAppDevelopersComponent,
    HireProjectManagerComponent,
    HireSalesForceDevelopersComponent,
    MobileAppDevelopmentComponent,
    DedicatedDevelopmentComponent,
    CustomSoftwareDevelopmentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [ApiService, AuthGuard, ServicesService],
  bootstrap: [AppComponent],
})
export class AppModule {}
