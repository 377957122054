import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-our-establishment",
  templateUrl: "./our-establishment.component.html",
  styleUrls: ["./our-establishment.component.css"],
})
export class OurEstablishmentComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
