import { ViewportScroller } from "@angular/common";
import { Component, HostListener, OnInit } from "@angular/core";
import { AuthenticationService } from "../../services/authentication.service";

@Component({
  selector: "app-homepage",
  templateUrl: "./homepage.component.html",
  styleUrls: ["./homepage.component.css"],
})
export class HomepageComponent implements OnInit {
  loader: boolean = true;
  constructor(
    private authenticationService: AuthenticationService,
    private scroll: ViewportScroller
  ) {}

  ngOnInit(): void {
    this.authenticationService.navigateToDashboardPageIfLoggedIn();
    setTimeout(() => {
      this.loader = false;
    }, 3000);
  }
  pageYoffset: any;
  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
}
