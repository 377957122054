import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-blog-one",
  templateUrl: "./blog-one.component.html",
  styleUrls: ["./blog-one.component.css"],
})
export class BlogOneComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
