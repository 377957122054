import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "src/app/services/authentication.service";
@Component({
  selector: "app-clientele",
  templateUrl: "./clientele.component.html",
  styleUrls: ["./clientele.component.css"],
})
export class ClienteleComponent implements OnInit {
  constructor(public authService: AuthenticationService) {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

  ngAfterViewInit() {
    // this.authService.loadScripts();
  }
}
