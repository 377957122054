import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-dedicated-development",
  templateUrl: "./dedicated-development.component.html",
  styleUrls: ["./dedicated-development.component.css"],
})
export class DedicatedDevelopmentComponent implements OnInit {
  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
