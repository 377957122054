import { ViewportScroller } from "@angular/common";
import { Component, HostListener } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from "./services/authentication.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  pageYoffset: any;
  @HostListener("window:scroll", ["$event"]) onScroll(event) {
    this.pageYoffset = window.pageYOffset;
  }
  constructor(
    private authService: AuthenticationService,
    private router:Router,
    private translateService: TranslateService,
    private scroll: ViewportScroller,
    private meta: Meta
  ) {
    translateService.addLangs(["tr", "en"]);
    const browserLanguage = translateService.getBrowserLang();
    translateService.use(
      browserLanguage.match(/en|tr/) ? browserLanguage : "en"
    );
    // this.meta.addTag({
    //   name: "description",
    //   content: "This is an article about Angular Meta service",
    // });
    router.events.forEach((event) => {
      if(event instanceof NavigationEnd) {
     this.authService.loadScripts();
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });
  }
  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }
}
