import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-expertise-experience",
  templateUrl: "./expertise-experience.component.html",
  styleUrls: ["./expertise-experience.component.css"],
})
export class ExpertiseExperienceComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
