import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ServicesService } from "src/app/services/services.service";
import { ApiService } from "./../../services/api.service";

@Component({
  selector: "app-contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: ["./contact-form.component.css"],
})
export class ContactFormComponent implements OnInit {
  constructor(
    public authService: AuthenticationService,
    private service: ServicesService
  ) {}
  contactForm: FormGroup;
  loader: boolean = false;
  emailPattern = this.service.emailpattern();
  ngOnInit(): void {
    this.contactForm = new FormGroup({
      name: new FormControl("", Validators.required),
      email: new FormControl("", [
        Validators.required,
        Validators.pattern(this.emailPattern),
      ]),
      phone: new FormControl("", [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$"),
      ]),
      comments: new FormControl(""),
    });
    // this.authService.loadScripts();
  }
  submitForm(form) {
    const data = {
      name: form.value.name,
      email: form.value.email,
      phone: form.value.phone,
      comments: form.value.comments,
    };
    // console.log(data, "ghjk");

    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 1000);
    this.service
      .post_api_call("save_contact_details", data)
      .subscribe((res: any) => {
        console.log(res, "ghj");

        this.loader = false;
        if (res) {
          form.reset();
          alert(res);
        } else {
          alert("Invalid authentication!");
        }
      });
  }
}
