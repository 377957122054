import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hire-full-stack-developers",
  templateUrl: "./hire-full-stack-developers.component.html",
  styleUrls: ["./hire-full-stack-developers.component.css"],
})
export class HireFullStackDevelopersComponent implements OnInit {
  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
