import { Component, OnInit } from "@angular/core";
// Sample
@Component({
  selector: "app-hire-reactjs-developers",
  templateUrl: "./hire-reactjs-developers.component.html",
  styleUrls: ["./hire-reactjs-developers.component.css"],
})
export class HireReactjsDevelopersComponent implements OnInit {
  constructor() {}

  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
