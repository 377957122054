import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-hire-project-manager",
  templateUrl: "./hire-project-manager.component.html",
  styleUrls: ["./hire-project-manager.component.css"],
})
export class HireProjectManagerComponent implements OnInit {
  loader: boolean = true;
  ngOnInit(): void {
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }
}
